import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";
import ScrollToTop from "./helpers/ScrollToTop";
import { Provider } from "react-redux";
import { store, persistor } from "./store/redux/store";
import { PersistGate } from "redux-persist/integration/react";
import SidebarProvider from "./store/context/SidebarProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <SidebarProvider>
        <Router>
          <ScrollToTop />
          <App />
        </Router>
      </SidebarProvider>
    </PersistGate>
  </Provider>
);
